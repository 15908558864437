import * as THREE from "three";
import Experience from "./Experience";
// import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js";
// import { RenderPass } from "three/addons/postprocessing/RenderPass.js";
// import { BokehPass } from "three/addons/postprocessing/BokehPass.js";
// import { OutputPass } from "three/addons/postprocessing/OutputPass.js";
import { pcss } from "@pmndrs/vanilla";

export default class Renderer {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.canvas = this.experience.canvas;
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.camera = this.experience.camera;

    // Options
    this.options = {
      clearColor: 0xf2f2f3,
      toneMapping: THREE.ACESFilmicToneMapping,
      toneMappingExposure: 1,
      bokeh: {
        focus: 12.0,
        aperture: 14.6,
        maxblur: 0.015,
      },
    };

    // Setup
    this.setInstance();

    // Post-processing setup
    // this.initPostProcessing();

    const reset = pcss({ size: 50, samples: 30, focus: 0 });

    // Debug
    this.setDebug();
  }

  setInstance() {
    this.instance = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      powerPreference: "high-performance",
    });

    THREE.ColorManagement.enabled = true;
    this.instance.outputColorSpace = THREE.SRGBColorSpace;
    this.instance.shadowMap.enabled = true;
    this.instance.shadowMap.type = THREE.PCFSoftShadowMap;
    this.instance.setClearColor(this.options.clearColor);

    this.instance.toneMapping = this.options.toneMapping;
    this.instance.toneMappingExposure = this.options.toneMappingExposure;

    this.instance.setSize(this.sizes.width, this.sizes.height);
    this.instance.setPixelRatio(this.sizes.pixelRatio);
  }

  initPostProcessing() {
    // Create composer for post-processing
    this.composer = new EffectComposer(this.instance);

    // Render pass
    const renderPass = new RenderPass(this.scene, this.camera.instance);
    this.composer.addPass(renderPass);

    // Bokeh pass
    this.bokehPass = new BokehPass(this.scene, this.camera.instance, {
      focus: this.options.bokeh.focus,
      aperture: this.options.bokeh.aperture * 0.00001,
      maxblur: this.options.bokeh.maxblur,
    });
    this.composer.addPass(this.bokehPass);

    // Output pass
    const outputPass = new OutputPass();
    this.composer.addPass(outputPass);
  }

  setDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder({
        title: "Experience",
      });

      this.debugFolder
        .addBinding(this.options, "clearColor", {
          label: "Background",
          view: "color",
        })
        .on("change", () => {
          this.instance.setClearColor(this.options.clearColor);
        });
      // Tone Mapping
      this.debugFolder
        .addBinding(this.options, "toneMapping", {
          options: {
            None: THREE.NoToneMapping,
            Linear: THREE.LinearToneMapping,
            Reinhard: THREE.ReinhardToneMapping,
            Cineon: THREE.CineonToneMapping,
            ACESFilmic: THREE.ACESFilmicToneMapping,
          },
        })
        .on("change", () => {
          this.instance.toneMapping = Number(this.options.toneMapping);
        });
      // // Tone Mapping Exposure
      this.debugFolder
        .addBinding(this.options, "toneMappingExposure", {
          label: "Exposure",
          min: 0,
          max: 2,
          step: 0.01,
        })
        .on("change", () => {
          this.instance.toneMappingExposure = this.options.toneMappingExposure;
        });

      // this.debugFolderBokeh = this.debug.ui.addFolder("Bokeh");

      // Bokeh Controls
      // this.debugFolderBokeh
      //   .add(this.options.bokeh, "focus", 0, 3000, 0.001)
      //   .name("Focus")
      //   .onChange((value) => {
      //     this.bokehPass.uniforms["focus"].value = value;
      //   });

      // this.debugFolderBokeh
      //   .add(this.options.bokeh, "aperture", 0, 20, 0.1)
      //   .name("Aperture")
      //   .onChange((value) => {
      //     this.bokehPass.uniforms["aperture"].value = value * 0.00001;
      //   });

      // this.debugFolderBokeh
      //   .add(this.options.bokeh, "maxblur", 0.0, 0.05, 0.001)
      //   .name("Max Blur")
      //   .onChange((value) => {
      //     this.bokehPass.uniforms["maxblur"].value = value;
      //   });
    }
  }

  resize() {
    this.instance.setSize(this.sizes.width, this.sizes.height);
    this.instance.setPixelRatio(Math.min(this.sizes.pixelRatio, 2));
    // this.composer.setSize(this.sizes.width, this.sizes.height);
  }

  update() {
    this.instance.render(this.scene, this.camera.instance);
    // this.composer.render();
  }
}
