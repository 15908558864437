import * as THREE from "three";
import Experience from "../Experience";

// import MeshReflectorMaterial from "../Materials/MeshReflectorMaterial";

export default class Floor {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;

    this.renderer = this.experience.renderer.instance;
    this.camera = this.experience.camera.instance;
    this.sizes = this.experience.sizes;
    this.time = this.experience.time;

    // Options
    this.options = {
      opacity: 0.2,
    };

    // Setup
    this.setShadow();
    // this.setReflection();

    // Debug
    this.setDebug();
  }

  setShadow() {
    this.shadow = new THREE.PlaneGeometry(10, 10);
    this.material = new THREE.ShadowMaterial({
      opacity: this.options.opacity,
    });

    this.mesh = new THREE.Mesh(this.shadow, this.material);
    this.mesh.rotation.x = -Math.PI * 0.5;
    this.mesh.position.set(0, -4.999, 0);
    this.mesh.receiveShadow = true;
    this.scene.add(this.mesh);
  }

  setReflection() {
    this.plane = new THREE.Mesh(new THREE.PlaneGeometry(10, 10));
    this.plane.position.set(0, -5, 0);
    this.plane.rotation.x = -Math.PI / 2;
    this.scene.add(this.plane);

    this.plane.material = new MeshReflectorMaterial(
      this.renderer,
      this.camera,
      this.scene,
      this.plane,
      {
        resolution: 1024,
        blur: [512, 128],
        mixBlur: 2.5,
        mixContrast: 1.5,
        mirror: 1,
      }
    );

    this.plane.material.setValues({
      roughnessMap: new THREE.TextureLoader().load("/textures/roughness.jpg"),
      normalMap: new THREE.TextureLoader().load("/textures/normal.png"),
      normalScale: new THREE.Vector2(0.3, 0.3),
    });
  }

  setDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder({
        title: "Floor",
        expanded: false,
      });
      this.debugFolder
        .addBinding(this.options, "opacity", {
          min: 0,
          max: 1,
          step: 0.001,
          label: "shadow opacity",
        })
        .on("change", () => {
          this.material.opacity = this.options.opacity;
        });
    }
  }

  update() {
    if (this.plane) this.plane.material.update();
  }
}
