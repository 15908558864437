import * as THREE from "three";
import Experience from "../Experience";
import { gsap } from "gsap";

import {
  MeshBVH,
  MeshBVHHelper,
  MeshBVHUniformStruct,
  FloatVertexAttributeTexture,
  shaderStructs,
  shaderIntersectFunction,
  SAH,
} from "three-mesh-bvh";

export default class Watch {
  constructor() {
    this.experience = new Experience();
    this.debug = this.experience.debug;
    this.scene = this.experience.scene;
    this.time = this.experience.time;
    this.camera = this.experience.camera.instance;
    this.renderer = this.experience.renderer.instance;
    this.resources = this.experience.resources;
    this.sizes = this.experience.sizes;

    // Options
    this.options = {
      animate: this.animateWatch.bind(this),
      reveal: () => {
        gsap.fromTo(
          this.camera.position,
          { y: 15 },
          {
            y: 0,
            duration: 2,
            ease: "power2.inOut",
          }
        );
      },
      bounces: 3.0,
      ior: 2.4,
      correctMips: true,
      chromaticAberration: false,
      aberrationStrength: 0.01,
      fresnel: false,
      color: 0xffffff,
    };

    // Setup
    this.resource = this.resources.items.watchModel;
    this.envMap = this.resources.items.envMapDiamond;

    // this.setDiamondMaterial();
    this.setModel();
    this.setAnimation();

    // Debug
    this.setDebug();

    // Get the range slider element
    const rangeSlider = document.getElementById("rangeSlider");

    // Update the timeline's progress based on the slider value
    rangeSlider.addEventListener("input", (e) => {
      const progress = e.target.value / 100; // Assume slider has values from 0 to 100
      this.tl.progress(progress); // Set the timeline's progress based on the slider
    });
  }

  getDiamondMaterial(options) {
    return new THREE.ShaderMaterial({
      uniforms: {
        envMap: new THREE.Uniform(this.envMap),
        bvh: new THREE.Uniform(new MeshBVHUniformStruct()),
        bounces: new THREE.Uniform(this.options.bounces),
        color: new THREE.Uniform(new THREE.Color(options.color)),
        ior: new THREE.Uniform(2.4),
        correctMips: new THREE.Uniform(this.options.correctMips),
        projectionMatrixInv: new THREE.Uniform(
          this.camera.projectionMatrixInverse
        ),
        viewMatrixInv: new THREE.Uniform(this.camera.matrixWorld),
        chromaticAberration: new THREE.Uniform(
          this.options.chromaticAberration
        ),
        aberrationStrength: new THREE.Uniform(0.01),
        resolution: new THREE.Uniform(
          new THREE.Vector2(this.sizes.width, this.sizes.height)
        ),
        fresnel: new THREE.Uniform(1.0),
      },
      vertexShader: /*glsl*/ `
          varying vec3 vWorldPosition;
          varying vec3 vNormal;
          uniform mat4 viewMatrixInv;
          void main() {
              vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
              vNormal = (viewMatrixInv * vec4(normalMatrix * normal, 0.0)).xyz;
              gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);
          }
          `,
      fragmentShader: /*glsl*/ `
          precision highp isampler2D;
          precision highp usampler2D;

          varying vec3 vWorldPosition;
          varying vec3 vNormal;

          uniform samplerCube envMap;
          uniform float bounces;
          ${shaderStructs}
          ${shaderIntersectFunction}
          uniform BVH bvh;
          uniform float ior;
          uniform vec3 color;
          uniform bool correctMips;
          uniform bool chromaticAberration;
          uniform mat4 projectionMatrixInv;
          uniform mat4 viewMatrixInv;
          uniform mat4 modelMatrix;
          uniform vec2 resolution;
          uniform bool chromaticAbberation;
          uniform float aberrationStrength;
          uniform float fresnel;

          float fresnelFunc(vec3 viewDirection, vec3 worldNormal) {
            return pow( 1.0 + dot( viewDirection, worldNormal), 10.0 );
          }

          vec3 totalInternalReflection(vec3 ro, vec3 rd, vec3 normal, float ior, mat4 modelMatrixInverse) {
            vec3 rayOrigin = ro;
            vec3 rayDirection = rd;
            rayDirection = refract(rayDirection, normal, 1.0 / ior);
            rayOrigin = vWorldPosition + rayDirection * 0.001;
            rayOrigin = (modelMatrixInverse * vec4(rayOrigin, 1.0)).xyz;
            rayDirection = normalize((modelMatrixInverse * vec4(rayDirection, 0.0)).xyz);
            for(float i = 0.0; i < bounces; i++) {
              uvec4 faceIndices = uvec4( 0u );
              vec3 faceNormal = vec3( 0.0, 0.0, 1.0 );
              vec3 barycoord = vec3( 0.0 );
              float side = 1.0;
              float dist = 0.0;
              bvhIntersectFirstHit( bvh, rayOrigin, rayDirection, faceIndices, faceNormal, barycoord, side, dist );
              vec3 hitPos = rayOrigin + rayDirection * max(dist - 0.001, 0.0);
              // faceNormal *= side;
              vec3 tempDir = refract(rayDirection, faceNormal, ior);
              if (length(tempDir) != 0.0) {
                rayDirection = tempDir;
                break;
              }
              rayDirection = reflect(rayDirection, faceNormal);
              rayOrigin = hitPos + rayDirection * 0.01;
            }
            rayDirection = normalize((modelMatrix * vec4(rayDirection, 0.0)).xyz);
            return rayDirection;
          }

          void main() {
            mat4 modelMatrixInverse = inverse(modelMatrix);
            vec2 uv = gl_FragCoord.xy / resolution;
            vec3 directionCamPerfect = (projectionMatrixInv * vec4(uv * 2.0 - 1.0, 0.0, 1.0)).xyz;
            directionCamPerfect = (viewMatrixInv * vec4(directionCamPerfect, 0.0)).xyz;
            directionCamPerfect = normalize(directionCamPerfect);
            vec3 normal = vNormal;
            vec3 rayOrigin = cameraPosition;
            vec3 rayDirection = normalize(vWorldPosition - cameraPosition);
            vec3 finalColor;

            if (chromaticAberration) {
              vec3 rayDirectionR = totalInternalReflection(rayOrigin, rayDirection, normal, max(ior * (1.0 - aberrationStrength), 1.0), modelMatrixInverse);
              vec3 rayDirectionG = totalInternalReflection(rayOrigin, rayDirection, normal, max(ior, 1.0), modelMatrixInverse);
              vec3 rayDirectionB = totalInternalReflection(rayOrigin, rayDirection, normal, max(ior * (1.0 + aberrationStrength), 1.0), modelMatrixInverse);
              float finalColorR = textureGrad(envMap, rayDirectionR, dFdx(correctMips ? directionCamPerfect: rayDirection), dFdy(correctMips ? directionCamPerfect: rayDirection)).r;
              float finalColorG = textureGrad(envMap, rayDirectionG, dFdx(correctMips ? directionCamPerfect: rayDirection), dFdy(correctMips ? directionCamPerfect: rayDirection)).g;
              float finalColorB = textureGrad(envMap, rayDirectionB, dFdx(correctMips ? directionCamPerfect: rayDirection), dFdy(correctMips ? directionCamPerfect: rayDirection)).b;
              finalColor = vec3(finalColorR, finalColorG, finalColorB) * color;
            } else {
              rayDirection = totalInternalReflection(rayOrigin, rayDirection, normal, max(ior, 1.0), modelMatrixInverse);
              finalColor = textureGrad(envMap, rayDirection, dFdx(correctMips ? directionCamPerfect: rayDirection), dFdy(correctMips ? directionCamPerfect: rayDirection)).rgb;
              finalColor *= color;
            }
            vec3 viewDirection = normalize(vWorldPosition - cameraPosition);

            float nFresnel = fresnelFunc(viewDirection, normal) * fresnel;

            gl_FragColor = vec4(mix(finalColor.rgb, vec3(1.0), nFresnel), 1.0);

            #include <tonemapping_fragment>
            #include <colorspace_fragment>
          }
          `,
    });
  }
  setModel() {
    this.model = this.resource.scene;

    this.diamondsGeometries = [];
    this.model.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        // Hours
        if (child.name === "网格001") {
          this.hoursHand = child;
        }
        if (child.name === "网格001_1") {
          this.hoursHandWhite = child;
        }
        // Minutes
        if (child.name === "网格003") {
          this.minutesHand = child;
        }
        if (child.name === "网格003_1") {
          this.minutesHandWhite = child;
        }
        // Seconds
        if (child.name === "S") {
          this.secondsHand = child;
        }
        // Pivot
        if (child.name === "轴心") {
          this.pivot = child;
        }

        // // numbers
        // if (child.name === "网格049_1") {
        //   this.numbersWhite = child;
        // }
        // if (child.name === "网格049_1") {
        //   this.numbersWhite = child;
        // }

        // Screws
        if (child.name === "表盘铆钉") {
          this.screws = child;
        }
        // Dial
        if (child.name === "网格023") {
          this.dialTop = child;
        }
        if (child.name === "网格023_1") {
          this.dial = child;
        }
        // Crown
        if (child.name === "niu_1") {
          this.crown = child;
        }
        if (child.name === "niu") {
          this.crownOne = child;
        }
        if (child.name === "niu_2") {
          this.crownTwo = child;
        }
        if (child.name === "八角心") {
          this.crownStar = child;
        }

        // if (child.name.includes("Round_Cut")) {
        //   this.diamondsGeometries.push(child);

        //   child.visible = false;
        //   child.material = this.material;
        // }

        // if (child.name.includes("Round_Cut033")) {
        //   this.geometry = child.geometry;
        //   this.pos = child.rotation;
        //   console.log(this.pos);
        //   child.visible = false;
        //   // child.material = this.material;
        // }

        child.castShadow = true;
        child.receiveShadow = true;
      }
    });
    this.model.scale.set(0.09, 0.09, 0.09);
    // this.model.scale.set(100, 100, 100);
    this.scene.add(this.model);

    // this.diamondsGeometries.forEach((child, index) => {
    //   const mergedGeometry = child.geometry;
    //   mergedGeometry.boundsTree = new MeshBVH(mergedGeometry.toNonIndexed(), {
    //     lazyGeneration: false,
    //     strategy: SAH,
    //   });

    //   const collider = new THREE.Mesh(mergedGeometry);
    //   collider.material.wireframe = true;
    //   collider.material.opacity = 0.5;
    //   collider.material.transparent = true;
    //   collider.visible = false;
    //   collider.boundsTree = mergedGeometry.boundsTree;
    //   this.scene.add(collider);
    //   const visualizer = new MeshBVHHelper(collider, 20);
    //   visualizer.visible = false;
    //   visualizer.update();
    //   this.scene.add(visualizer);
    //   const material = this.getDiamondMaterial({
    //     color: child.material.color,
    //   });
    //   const diamond = new THREE.Mesh(child.geometry, material);
    //   diamond.material.uniforms.bvh.value.updateFrom(collider.boundsTree);
    //   diamond.position.set(0, 0, 0);
    //   diamond.rotation.set(Math.PI * 0.5, 0, 0);
    //   this.scene.add(diamond);
    // });

    // const mergedGeometry = this.geometry;
    // mergedGeometry.boundsTree = new MeshBVH(mergedGeometry.toNonIndexed(), {
    //   lazyGeneration: false,
    //   strategy: SAH,
    // });

    // const collider = new THREE.Mesh(mergedGeometry);
    // collider.material.wireframe = true;
    // collider.material.opacity = 0.5;
    // collider.material.transparent = true;
    // collider.visible = false;
    // collider.boundsTree = mergedGeometry.boundsTree;
    // this.scene.add(collider);
    // const visualizer = new MeshBVHHelper(collider, 20);
    // visualizer.visible = false;
    // visualizer.update();
    // this.scene.add(visualizer);

    // this.diamond = new THREE.Mesh(this.geometry, this.material);
    // this.diamond.material.uniforms.bvh.value.updateFrom(collider.boundsTree);
    // this.diamond.position.set(0, 0, 0);
    // this.diamond.rotation.set(Math.PI * 0.5, 0, 0);
    // this.scene.add(this.diamond);
  }

  animateWatch() {
    if (this.tl.progress() === 0 || this.tl.reversed()) {
      this.tl.play();
    } else {
      this.tl.reverse();
    }
  }

  setAnimation() {
    if (this.hoursHand && this.minutesHand && this.secondsHand) {
      this.setHandsRotation();
      setInterval(() => {
        this.setHandsRotation();
      }, 1000);
      gsap.to(this.secondsHand.rotation, {
        duration: 60,
        z: -Math.PI * 2,
        repeat: -1,
        ease: "none",
      });

      // create timeline
      this.tl = gsap.timeline();
      this.tl.pause();

      const timelineOptions = {
        minimumDistance: 20,
        duration: 1,
      };
      // Seconds animation (add with a small stagger after minutes)
      this.tl
        .addLabel("seconds") // starts 1.5 seconds before the end of minutes
        .to(
          this.secondsHand.position,
          {
            z:
              this.secondsHand.position.z + timelineOptions.minimumDistance + 6,
            duration: timelineOptions.duration,
            ease: "power2.inOut",
          },
          "seconds"
        );
      // Minutes animation (animate both hands together with a label)
      this.tl
        .addLabel("minutes", "-=1") // starts 1.5 seconds before the end of hours
        .to(
          this.minutesHandWhite.position,
          {
            z:
              this.minutesHandWhite.position.z +
              timelineOptions.minimumDistance +
              4,
            duration: timelineOptions.duration,
            ease: "power2.inOut",
          },
          "minutes"
        )
        .to(
          this.minutesHand.position,
          {
            z:
              this.minutesHand.position.z + timelineOptions.minimumDistance + 4,
            duration: timelineOptions.duration,
            ease: "power2.inOut",
          },
          "minutes"
        );
      // Hours animation (animate both hands together with a label)
      this.tl
        .addLabel("hours", "-=1")
        .to(
          this.hoursHandWhite.position,
          {
            z:
              this.hoursHandWhite.position.z +
              timelineOptions.minimumDistance +
              2,
            duration: timelineOptions.duration,
            ease: "power2.inOut",
          },
          "hours"
        )
        .to(
          this.hoursHand.position,
          {
            z: this.hoursHand.position.z + timelineOptions.minimumDistance + 2,
            duration: timelineOptions.duration,
            ease: "power2.inOut",
          },
          "hours"
        );
      // Pivot
      this.tl.addLabel("pivot", "-=1").to(
        this.pivot.position,
        {
          z: this.pivot.position.z + timelineOptions.minimumDistance,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "pivot"
      );
      // Screws
      this.tl.addLabel("screws", "-=1").to(
        this.screws.position,
        {
          z: this.screws.position.z + timelineOptions.minimumDistance - 2,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "screws"
      );
      // Dial
      this.tl.addLabel("dial", "-=1").to(
        this.dialTop.position,
        {
          z: this.dialTop.position.z + timelineOptions.minimumDistance - 8,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "dial"
      );
      this.tl.addLabel("dial", "-=1").to(
        this.dial.position,
        {
          z: this.dial.position.z + timelineOptions.minimumDistance - 8,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "dial"
      );
      // Crown
      //
      this.tl.addLabel("crown", "-=1").to(
        this.crown.position,
        {
          x: this.crown.position.x + 8,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "crown"
      );
      this.tl.addLabel("crown", "-=1").to(
        this.crownOne.position,
        {
          x: this.crownOne.position.x + 8,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "crown"
      );
      this.tl.addLabel("crown", "-=1").to(
        this.crownTwo.position,
        {
          x: this.crownTwo.position.x + 8,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "crown"
      );
      this.tl.addLabel("crown", "-=1").to(
        this.crownStar.position,
        {
          x: this.crownStar.position.x + 8,
          duration: timelineOptions.duration,
          ease: "power2.inOut",
        },
        "crown"
      );
    }
  }

  setHandsRotation() {
    let date = new Date();
    let minutes = date.getMinutes();
    let hours = date.getHours();

    this.hoursHand.rotation.z = -THREE.MathUtils.degToRad(
      hours * 30 + minutes / 2
    );
    this.hoursHandWhite.rotation.z = -THREE.MathUtils.degToRad(
      hours * 30 + minutes / 2
    );
    this.minutesHand.rotation.z = -THREE.MathUtils.degToRad(minutes * 6);
    this.minutesHandWhite.rotation.z = -THREE.MathUtils.degToRad(minutes * 6);
  }

  setDebug() {
    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder({ title: "Watch" });
      // this.debugFolder.addBinding(this.options, "animate");
      // this.debugFolder.addBinding(this.options, "reveal");
    }
  }

  update() {}
}
