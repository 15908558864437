export default [
  // {
  //   name: "diamondModel",
  //   type: "gltfModel",
  //   path: "/models/diamond/diamond.glb",
  // },
  // {
  //   name: "envMapDiamond",
  //   type: "cubeTexture",
  //   path: [
  //     "/textures/environmentMap/diamond/px.png",
  //     "/textures/environmentMap/diamond/nx.png",
  //     "/textures/environmentMap/diamond/py.png",
  //     "/textures/environmentMap/diamond/ny.png",
  //     "/textures/environmentMap/diamond/pz.png",
  //     "/textures/environmentMap/diamond/nz.png",
  //   ],
  // },
  {
    name: "watchModel",
    type: "gltfModel",
    path: "/models/watch/alpine_eagle.glb",
  },
  {
    name: "envMap",
    type: "cubeTexture",
    path: [
      "/textures/environmentMap/alpine/px.png",
      "/textures/environmentMap/alpine/nx.png",
      "/textures/environmentMap/alpine/py.png",
      "/textures/environmentMap/alpine/ny.png",
      "/textures/environmentMap/alpine/pz.png",
      "/textures/environmentMap/alpine/nz.png",
    ],
  },
];
