import Experience from "../Experience";
import Environment from "./Environment";
import Loader from "./Loader";
import Watch from "./Watch";
import Diamond from "./Diamond";
import Floor from "./Floor";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    // this.loader = new Loader();

    // Wait for resources to be loaded
    this.resources.on("ready", () => {
      // Setup
      this.watch = new Watch();
      // this.diamond = new Diamond();
      this.floor = new Floor();
      this.environment = new Environment();
      // Show Experience
      // this.loader.hideLoader();
    });
  }

  update() {
    if (this.watch) this.watch.update();
    if (this.floor) this.floor.update();
  }
}
